.caseStydy_wrapper {
  width: 100%;
  h1 {
    font-weight: 300 !important;
    font-family: ProductSansLight;
    color: #000000;
  }
  p {
    line-height: 30px;
    margin-bottom: 17px;
    font-size: 16px;
    color: black;
    font-family: ProductSansLight;
    font-weight: 300;
    opacity: 0.7;
    b {
      font-size: 18px;
    }
  }
  .profile {
    img {
      width: 100%;
    }
  }
}
.case {
  margin: 50px 0;
  .caseFlex {
    display: flex;
    justify-content: space-between;
    width: 50%;
    margin: 30px auto;

    & > div:nth-child(1) {
      width: 25%;
    }

    & > div:nth-child(2) {
      width: 75%;
      h4 {
        margin-bottom: 15px;
      }
      p {
        margin-bottom: 15px;
      }
    }
  }
  .fullwidth_image {
    width: 75%;
    margin: 0 auto;
    text-align: center;
    img {
      width: 100%;
    }
  }

  .fullwidth {
    width: 100%;

    text-align: center;
    img {
      width: 100%;
    }
  }

  .container_2 {
    width: 65%;
    margin: 0 auto;
  }
  .box_wrapper {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-wrap: wrap;
    width: 65%;
    margin: 0 auto;
    & > div {
      width: 45%;
      display: flex;
      justify-content: space-between;
      flex-direction: column;
      align-items: center;
      margin-bottom: 60px;
      span {
        margin-bottom: 40px;
      }
    }
  }

  .typeface {
    div {
      p {
        margin-top: 30px;
      }
    }
  }
  .colors {
    div {
      p {
        margin-top: 30px;
      }
    }
  }
  .img_flex {
    display: flex;
    justify-content: space-between;
    align-items: center;
    img {
      width: 47%;
    }
  }
  .img_flex_column {
    img {
      margin-bottom: 20px;
    }
  }
  .interface_wrap {
    width: 100%;
    margin: 40px auto;
  }
  .interface_img_wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    img {
      width: 47%;
      margin-bottom: 70px;
    }
  }
  & > div:nth-child(2) {
    width: 60%;
    margin: 0 auto;
  }

  .usabilityTestingBox {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;

    text-align: center;
    div {
      width: 45%;
      margin-bottom: 20px;
    }
  }
  .usabilityTestingBox2 {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-wrap: wrap;
    margin: 0 auto;
    width: 90%;
    img {
      width: 47%;
      margin-bottom: 30px;
    }
  }
}

.blackSection {
  background-color: black;
  color: white;
  padding: 90px 0;
  margin: 30px 0;
  h1 {
    color: white;
    font-family: ProductSans;
    font-weight: 600 !important;
  }
  & > div {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    width: 60%;
    margin: 0 auto;
    div {
      width: 19%;
      h1 {
        font-size: 59px;
        line-height: 73px;
      }
      p {
        color: white;
        font-size: 15px;
        line-height: 18px;
      }
    }
  }
}
.whiteSection {
  padding: 20px 0;
  margin: 30px 0;

  & > div {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    width: 60%;
    margin: 0 auto;
    div {
      width: 25%;
      h1 {
        font-size: 59px;
        line-height: 73px;
        font-family: ProductSans;
        font-weight: 600 !important;
      }
    }
  }
}
.user_profile {
  width: 75%;
  & > div {
    display: flex;
    justify-content: space-between;
    align-items: center;
    h4,
    h3 {
      margin-bottom: 15px;
    }
    & > div:nth-child(1) {
      text-align: right;
      width: 44%;
      img {
        width: 70%;
      }
    }
    & > div:nth-child(2) {
      width: 53%;
      padding-top: 30px;
    }
    .userInfo {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      flex-wrap: wrap;
      margin-top: 50px;
      & > div {
        width: 46%;
        margin-top: 0px;
      }
      & > div:nth-child(3) {
        width: 100%;
        margin-top: 50px;
      }
    }
  }
}

@media (max-width: "1100px") {
  .case {
    .caseFlex {
      width: 80%;
    }
  }
  .blackSection {
    & > div {
      width: 90%;
    }
  }
}

@media (max-width: "728px") {
  .caseStydy_wrapper {
    p {
      line-height: 24px;
      margin-bottom: 15px;
    }
    .profile {
      img {
        width: 100%;
      }
    }
  }
  .case {
    margin: 50px 0;
    .caseFlex {
      width: 100%;
      margin: 45px auto;
      flex-direction: column;
      & > div:nth-child(1) {
        width: 100%;
        margin-bottom: 20px;
      }
      & > div:nth-child(2) {
        width: 100%;
        h4 {
          margin-bottom: 5px;
        }
        p {
          margin-bottom: 10px;
        }
      }
    }
    .fullwidth_image {
      width: 100%;
      img {
        width: 100%;
      }
    }
    .container_2 {
      width: 100%;
    }
    .box_wrapper {
      width: 100%;
      flex-direction: column;
      & > div {
        width: 100%;
        margin-bottom: 80px;
        text-align: center;
      }
    }

    .typeface {
      div {
        p {
          margin-top: 30px;
        }
      }
    }
    .colors {
      div {
        p {
          margin-top: 30px;
        }
      }
    }
    .img_flex {
      flex-direction: column;
      img {
        width: 100%;
      }
    }
    .interface_wrap {
      width: 100%;
      margin: 40px auto;
    }
    .interface_img_wrapper {
      flex-direction: column;
      img {
        width: 100%;
        margin: 30px 0;
      }
    }
    & > div:nth-child(2) {
      width: 100%;
      margin: 0 auto;
    }
    .usabilityTestingBox {
      flex-direction: column;
      margin-bottom: 30px;
      div {
        width: 100%;
        margin-bottom: 40px;
      }
    }
    .usabilityTestingBox2 {
      // display: flex;
      // justify-content: space-between;
      // align-items: flex-start;
      // flex-wrap: wrap;
      // margin: 0 auto;
      width: 100%;
      img {
        width: 100%;
        margin-bottom: 30px;
      }
    }
  }
  .blackSection {
    padding: 40px 0;
    margin: 30px 0;
    & > div {
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-direction: column;
      width: 90%;
      div {
        width: 50%;
        margin: 40px 0;
        h1 {
          font-size: 59px;
          line-height: 73px;
        }
      }
    }
  }

  .whiteSection {
    padding: 40px 0;
    margin: 30px 0;
    & > div {
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-direction: column;
      div {
        width: 50%;
        margin: 20px 0;
        h1 {
          font-size: 59px;
          line-height: 73px;
        }
      }
    }
  }
  .user_profile {
    width: 100%;
    & > div {
      flex-direction: column;
      h4,
      h3 {
        margin-bottom: 15px;
      }
      & > div:nth-child(1) {
        width: 100%;
        img {
          width: 100%;
        }
      }
      & > div:nth-child(2) {
        width: 90%;
        margin: 0 auto;
        padding-top: 30px;
      }
      .userInfo {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        flex-wrap: wrap;
        margin-top: 0px;
        & > div {
          width: 100%;
          margin-top: 20px;
        }
        & > div:nth-child(3) {
          margin-top: 20px;
        }
      }
    }
  }
}
