.other_case_study {
  h1 {
    font-family: ProductSansLight;
    font-weight: 300;
  }
  p {
    line-height: 28px;
    margin-bottom: 15px;
    font-family: ProductSansLight;
    font-weight: 300;
  }
  .profile {
    img {
      width: 100%;
    }
  }
  .text_wrapper {
    width: 40%;
    margin: 60px auto;
    h4 {
      margin-bottom: 20px;
      margin-top: 50px;
    }
  }

  .img_wrapper {
    width: 65%;
    margin: 0 auto;
    img {
      width: 100%;
      margin: 30px 0;
    }
  }
  .img_wrapper_flex {
    width: 70%;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    img {
      width: 47%;
      margin-bottom: 30px;
    }
  }

  .caseFlex {
    display: flex;
    justify-content: space-between;
    width: 50%;
    margin: 60px auto;

    & > div:nth-child(1) {
      width: 25%;
    }

    & > div:nth-child(2) {
      width: 75%;
      h4 {
        margin-bottom: 15px;
      }
      p {
        margin-bottom: 15px;
      }
    }
  }
  .sign {
    text-align: center;
  }
}

@media (max-width: "728px") {
  .other_case_study {
    p {
      line-height: 28px;
      margin-bottom: 15px;
    }

    .text_wrapper {
      width: 90%;
      margin: 40px auto;
      h4 {
        margin-bottom: 15px;
        margin-top: 30px;
      }
    }

    .img_wrapper {
      width: 90%;
      img {
        width: 100%;
        margin: 30px 0;
      }
    }
    .img_wrapper_flex {
      width: 100%;

      flex-direction: column;
      img {
        width: 100%;
        margin-bottom: 30px;
      }
    }
    .caseFlex {
      width: 90%;
      margin: 45px auto;
      flex-direction: column;
      & > div:nth-child(1) {
        width: 100%;
        margin-bottom: 20px;
      }
      & > div:nth-child(2) {
        width: 100%;
        h4 {
          margin-bottom: 5px;
        }
        p {
          margin-bottom: 10px;
        }
      }
    }
  }
}
