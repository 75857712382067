.infoSection {
  .caseTitleWrap {
    a {
      color: #3c4043;
      border-bottom: 1px solid #3c4043;
    }
    p {
      margin: 10px 0;
      span {
        span {
          border-bottom: 1px solid #3c4043;
        }
      }
    }
  }

  .caseTitleWrap {
    width: 95%;
    margin: 0 auto;
    margin-top: 30px;
  }
  p {
    color: #3c4043;
    // line-height: 40px;
  }
  .infoWrapper {
    width: 80%;
    margin: 0 auto;
    border-top: 2px solid #3c4043;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    p {
      line-height: 37px;
    }
    padding: 104px 0;
    & > div:nth-child(1) {
      width: 50%;
      h3 {
        font-weight: 600;
      }
      p {
        color: #202124;
        font-family: ProductSansLight;
        font-weight: 300;
        opacity: 0.5;
      }
    }
    & > div:nth-child(2) {
      width: 50%;
      h3 {
        margin-bottom: 15px;
        font-weight: 600;
      }
      h2 {
        margin-bottom: 25px;
        color: #3c4043;
        font-family: ProductSansLight;
        font-weight: 300;
        position: relative;
        display: inline-block;
        &::after {
          content: "";
          position: absolute;
          bottom: 0;
          width: 0%;
          height: 3px;
          background-color: black;
          left: 0;
          transition: all 400ms ease-in-out;
        }
      }
      span {
        font-family: ProductSansLight;
        font-weight: 300;
        color: #3c4043;
        display: block;
      }
      a {
        display: block;
        // cursor: pointer;
        &:hover {
          h2 {
            &::after {
              content: "";
              width: 100%;
            }
          }
        }
      }
      p {
        color: #202124;
        font-family: ProductSansLight;
        font-weight: 300;
        opacity: 0.5;
      }
      .flex_wrap {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        flex-wrap: wrap;
        div {
          width: 47%;

          h3 {
            margin-bottom: 0;
            line-height: 20px;
          }
          P {
            margin: 0;
            line-height: 28px;
          }
        }
        & div:nth-child(1) {
          margin-bottom: 52px;
        }
        & div:nth-child(2) {
          margin-bottom: 52px;
        }
      }
      .sec04 {
        h3 {
          margin-bottom: 0px;
          font-size: 20px;
          line-height: 24px;
          font-weight: 600;
        }
        p {
          line-height: 20px;
        }
      }
    }
  }
}

@media (max-width: 768px) {
  .infoSection {
    .caseTitleWrap {
      width: 90%;
    }
    p {
      color: #3c4043;
      line-height: 35px;
    }
    .infoWrapper {
      width: 90%;
      border-top: 2px solid #3c4043;
      padding: 70px 0;
      flex-wrap: wrap;
      & > div:nth-child(1) {
        width: 100%;
        margin-bottom: 30px;
      }
      & > div:nth-child(2) {
        width: 100%;
        h2 {
          margin-bottom: 20px;
          font-size: 32px;
        }
        .flex_wrap {
          flex-direction: column;
          & div:nth-child(1) {
            margin-bottom: 20px;
          }
          & div:nth-child(2) {
            margin-bottom: 20px;
          }
          div {
            width: 100%;
            margin: 20px 0;
          }
        }
        .sec04 {
          h3 {
            margin-bottom: 0px;
            font-weight: 500;
            font-size: 20px;
            line-height: 24px;
          }
          p {
            line-height: 20px;
          }
        }
      }
    }
  }
}
