.wrappHome {
  width: 85%;
  margin: 0 auto;
  h1 {
    font-size: 90px;
    line-height: 100px;
    font-weight: 400;
  }
  p {
    line-height: 30px;
  }
}
.head {
  width: 85%;
  margin: 80px 0;
  margin-bottom: 80px;
}
.infoWrap {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-wrap: wrap;
  width: 90%;
  & > div {
    width: 40%;
    margin-bottom: 50px;

    a {
      h2 {
        font-size: 42px;
        color: #202124;
        display: inline-block;
        position: relative;
        margin-bottom: 15px;
        &::after {
          content: "";
          position: absolute;
          bottom: 0;
          width: 0%;
          height: 3px;
          background-color: black;
          left: 0;
          transition: all 400ms ease-in-out;
        }
      }
      p {
        color: #3c4043;
        opacity: 0.5;
        display: inline-block;
      }
      &:hover {
        h2 {
          &::after {
            content: "";
            width: 100%;
          }
        }
      }
    }

    .seeMore {
      display: flex;
      align-items: center;
      margin-top: 15px;
      img {
        margin-left: 15px;
      }
    }
  }
}
.resourcesSection {
  h1 {
    margin-bottom: 40px;
  }
  width: 100%;
  .resourcesWrittings {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    .res_wrap {
      width: 47%;
      & > div:nth-child(1) {
        & > div {
          margin-bottom: 27px;
          border-bottom: 1px solid rgba(60, 64, 67, 0.2);
          padding-bottom: 27px;
          p {
            color: #3c4043;
            opacity: 0.5;
            font-family: ProductSansLight;
            font-weight: 300;
            line-height: 30px;
          }
          span {
            color: #3c4043;
            font-family: ProductSansLight;
            font-weight: 300;
            line-height: 30px;
          }
          h3 {
            color: #202124;
            font-size: 18px;
            line-height: 27px;
          }
        }
      }
    }
  }

  & > div:nth-child(3) {
    display: flex;
    justify-content: flex-end;
    a {
      display: flex;
      align-items: center;
      color: #202124;
      img {
        margin-left: 10px;
      }
    }
  }
}

.beforeSticky {
  position: relative;
}
.work {
  // margin-top: 90px;
}
.section2 {
  top: 0;
  height: 100vh;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
  & > div {
    overflow-x: scroll;
    scroll-behavior: auto;
    margin-top: 15px;
    & > .card_wrapper {
      display: flex;
      justify-content: space-between;
      align-items: center;

      width: 160%;
      // height: 75vh;
      & > .card {
        width: 23%;
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;

        & > .beforeWrap {
          width: 95%;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          .learnMore {
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: flex-start;

            p {
              display: flex;
              align-items: center;
              img {
                margin-left: 15px;
              }
            }
          }
          a {
            height: 100%;
            display: flex;
            align-items: center;
            & > .contain {
              width: 100%;
              display: flex;
              flex-direction: column;
              justify-content: space-between;
              align-items: flex-start;
              margin: auto;

              & > img {
                width: 100% !important;
                height: 100% !important;
              }
              h2 {
                color: #3c4043;
                margin-top: 15px;
                font-family: ProductSansLight;
                font-weight: 300;
              }
              p {
                color: #3c4043;
                opacity: 0.5;
                font-size: 16px;
                font-family: ProductSansLight;
                font-weight: 300;
              }
            }
            .img_wrap {
              position: relative;
              // border: 5px solid red;
              height: 80%;
              width: 100%;
              img {
                width: 100%;
              }
              .thumb {
                position: absolute;
                top: 0;
                width: 100%;
                height: 100%;
                background-color: black;
                opacity: 0;
                transition: all 400ms ease-in-out;
                &:hover {
                  opacity: 0.5;
                }
              }
            }
          }
        }
        p {
          font-size: 20px;
        }
        h3 {
          font-weight: 500;
        }
        .hover {
          background-position: center;
          background-size: cover;
        }
      }
      & > .card:nth-child(5) {
        width: 200px;
        text-align: right;
        display: flex;
        justify-content: flex-end;
        a {
        }
        .learnMore {
          display: flex;
          justify-content: flex-end;
        }
      }
    }
  }
}
.resourcesWrittings {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  & > div {
    width: 47%;
  }
}
.sticky {
  position: sticky;
  top: 0;
  height: 100vh;
}
.beforeSticky2 {
  position: relative;
}
.sectionInfo {
  margin: 0 auto;
  width: 100%;
  max-width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  .box_wrapper {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0 auto;

    .box1 {
      width: 47%;
      border-right: 1px solid black;
      word-break: normal;
      padding: 0px 30px;
      padding-bottom: 35px;
      h3 {
        margin-top: 30px;
        margin-bottom: 20px;
      }
      p {
        color: #3c4043;
        opacity: 0.5;
        font-family: ProductSansLight;
        font-weight: 300;
      }
    }

    .box2 {
      width: 53%;
      overflow-x: scroll;
      scroll-behavior: auto;
      font-family: ProductSansLight;
      font-weight: 300;
      p {
        color: #3c4043;
        font-family: ProductSansLight;
        font-weight: 300;
      }
      h2,
      span {
        font-family: ProductSansLight;
        font-weight: 300;
      }
      h2 {
        font-size: 60px;
        line-height: 90px;
      }

      .card_wrapper {
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
        // width: 270%;
        width: 250%;
        width: 1800px;
        .seeMore {
          display: flex;
          align-items: center;
          img {
            margin-left: 10px;
          }
        }
        .card {
          width: 15%;
          padding-left: 30px;
          .present {
            color: #36ca8b;
          }
          & > div {
            width: 100%;
          }
        }
        .card:nth-child(3) {
          width: 25%;
        }
        .card:nth-child(4) {
          width: 20%;
        }
        .card:nth-child(5) {
          width: 25%;
        }
        .card:nth-child(6) {
          width: 10%;
          height: 100%;
          align-self: center;
        }
      }
    }
  }
}

.home_footer {
  width: 50%;
  margin: 0 auto;
  text-align: center;
  padding: 30px 0;
  a {
    margin: 0 15px;
  }
}
@media (max-width: 1540px) {
  .section2 {
    height: 100vh;
    // background-color: blue;
    & > div {
      margin-top: 15px;
      & > .card_wrapper {
        width: 180%;
        width: 135%;

        & > .card:nth-child(5) {
          width: 200px;
        }
      }
    }
  }
}
@media (max-width: 1440px) {
  .section2 {
    // height: 100vh;
    // background-color: red;
    & > div {
      margin-top: 15px;
      & > .card_wrapper {
        width: 145%;
        & > .card:nth-child(5) {
          width: 200px;
        }
      }
    }
  }
}
@media (max-width: 1280px) {
  .wrappHome {
    width: 90%;
    h1 {
      font-size: 50px;
      line-height: 60px;
    }
  }

  .head {
    width: 100%;
    margin: 50px 0;
  }
  .infoWrap {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-wrap: wrap;
    width: 100%;
    & > div {
      width: 47%;
      margin-bottom: 40px;
    }
  }
  .work {
    margin-top: 50px;
    margin-bottom: 40px;
  }
  .section2 {
    margin: 20px 0;
    height: initial;
    & > div {
      overflow-x: initial;
      height: initial;

      & > .card_wrapper {
        flex-wrap: wrap;
        width: 100%;
        align-items: flex-start;
        & > .card {
          width: 49%;
          height: initial;
          margin-bottom: 70px;
          position: relative;
          & > .beforeWrap {
            width: 95%;
            height: 70%;
            height: 100%;

            .learnMore {
              height: 100%;
            }
            a {
              & > .contain {
                width: 100%;
                height: 70vh;
                height: initial;
                margin: auto;
                & > img {
                  //   width: 100% !important;
                  max-width: 100%;
                  height: initial;
                }
              }
            }
          }
        }
        & > .card:nth-child(5) {
          width: 100%;
        }
      }
    }
  }

  .resourcesSection {
    h1 {
      margin-bottom: 40px;
    }
    width: 100%;
    .resourcesWrittings {
      .res_wrap {
        width: 47%;
      }
    }
  }
  .sticky {
    position: static;
    height: initial;
  }

  .beforeSticky2 {
    height: 100%;
    position: initial;
    // margin-bottom: 80px;
  }

  .sectionInfo {
    padding: 0px;
    width: 100%;
    height: initial;
    margin: 40px 0;
    .box_wrapper {
      justify-content: space-between;
      align-items: center;
      flex-direction: column;
      .box1 {
        width: 100%;
        border-right: 0px;
        padding: 0;
        margin-bottom: 30px;
      }
      .box2 {
        width: 100%;
        .card_wrapper {
          display: flex;
          justify-content: space-between;
          width: 100%;
          flex-wrap: wrap;
          padding-left: 0;
          .card {
            width: 48%;
            padding-left: 0;
            margin-bottom: 40px;

            & > div {
              width: 100%;
              sup {
                font-size: 16px;
              }
            }
          }
          .card:nth-child(3) {
            width: 48%;
          }
          .card:nth-child(4) {
            width: 48%;
          }
          .card:nth-child(5) {
            width: 48%;
          }
          .card:nth-child(6) {
            width: 100%;
            padding: 50px 0;
            .seeMore {
              justify-content: center;
            }
          }
        }
      }
    }
  }
  .home_footer {
    width: 80%;
  }
}

@media (max-width: 768px) {
  .wrappHome {
    width: 90%;
    h1 {
      font-size: 40px;
      line-height: 50px;
    }
  }

  .head {
    width: 100%;
    margin: 30px 0;
    text-align: center;
    h1 {
      text-align: left;
    }
  }
  .infoWrap {
    flex-direction: column;
    & > div {
      width: 100%;
      margin-bottom: 30px;
      a {
        h2 {
          font-size: 30px;
          margin-bottom: 10px;
        }
      }
    }
  }
  .work {
    margin-top: 40px;
    margin-bottom: 30px;
  }
  .beforeSticky {
    height: initial;
    position: relative;
  }
  .section2 {
    margin-top: 10px;
    height: 100%;
    h1 {
      margin: 0;
    }
    & > div {
      overflow-x: initial;
      height: initial;

      & > .card_wrapper {
        flex-wrap: wrap;
        width: 100%;
        & > .card {
          width: 100%;
          height: initial;
          margin-bottom: 40px;
          & > .beforeWrap {
            width: 95%;
            height: initial;
            .learnMore {
              height: 100%;
              align-items: center;
              justify-content: center;
              margin: 0px 0;
            }
            a {
              & > .contain {
                img {
                  margin: 0 auto;
                }
                p {
                  margin: 0;
                }
                h2 {
                  font-size: 30px;
                  line-height: 28px;
                  margin-top: 7px;
                }
              }
            }
          }
          p {
            font-size: 20px;
          }
        }
      }
    }
  }
  .resourcesSection {
    h1 {
      margin-bottom: 20px;
    }

    .resourcesWrittings {
      flex-direction: column;

      .res_wrap {
        width: 100%;

        & > div {
          margin: 10px 0;
          padding-bottom: 10px;
        }
        & > div:nth-child(2) {
          margin: 0px 0;
          display: flex;
          justify-content: center;
        }

        & > div:nth-child(1) {
          & > div {
            margin-bottom: 30px;
            padding-bottom: 20px;
          }
        }
      }
    }
    & > div:nth-child(3) {
      justify-content: center;
    }
  }
  .beforeSticky2 {
    height: 100%;
    margin-bottom: 40px;
  }
  .sectionInfo {
    padding: 0px;
    width: 100%;
    .box_wrapper {
      .box2 {
        // h2 {

        // }
        .card_wrapper {
          .card {
            width: 100%;
            padding-left: 0;
            margin-bottom: 15px;
            h2 {
              font-size: 34px;
              line-height: 40px;
            }
          }
          .card:nth-child(3) {
            width: 100%;
          }
          .card:nth-child(4) {
            width: 100%;
          }
          .card:nth-child(5) {
            width: 100%;
          }
          .card:nth-child(6) {
            width: 100%;
            padding: 20px 0;
          }
        }
      }
    }
  }
  .home_footer {
    width: 100%;
    padding: 10px 0;
    a {
      margin: 15px;
    }
    div {
      margin: 10px 0;
    }
  }
}
