.cardWrapper {
  width: 85%;
  margin: 0 auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  .card {
    width: 47%;
    margin-bottom: 67px;

    .img_wrapper {
      position: relative;
      height: 348px;
      img {
        width: 100%;
        height: 100%;
      }
      .thumb {
        position: absolute;
        display: block;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        transition: all 300ms ease-in-out;
        &:hover {
          background-color: rgba(0, 0, 0, 0.4);
        }
      }
    }
    .textBox {
      padding-top: 20px;
      p {
        font-size: 16px;
        margin-bottom: 7px;
      }
      span {
        opacity: 0.5;
      }
    }
  }
}

@media (max-width: 1150px) {
  .cardWrapper {
    // width: 100%;
  }
}

@media (max-width: 728px) {
  .cardWrapper {
    width: 100%;
    flex-direction: column;
    .card {
      width: 100%;
      margin-bottom: 47px;
      .img_wrapper {
        height: initial;
        img {
          width: 100%;
          height: 100%;
        }
      }
    }
  }
}
