.foundation {
  max-width: 1440px;
  margin: 0 auto;
  * {
    color: rgb(55, 53, 47);
    line-height: 1.5;
    font-family: ui-sans-serif, -apple-system, BlinkMacSystemFont, "Segoe UI",
      Helvetica, "Apple Color Emoji", Arial, sans-serif, "Segoe UI Emoji",
      "Segoe UI Symbol";
  }
  .hero {
    height: 300px;
    // border: 2px solid red;
    background-image: url("../assets//images/foundation.jpeg");
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    margin-bottom: 100px;
    position: relative;
    .thumb {
      width: 50%;
      margin: 0 auto;
      img {
        width: 130px;
        // height: 300px;
        position: absolute;
        bottom: -40px;
      }
    }
  }
  & > .wrapper {
    width: 50%;
    margin: 0 auto;
    padding-bottom: 100px;
    h1 {
      font-size: 40px;
      font-family: ui-sans-serif, -apple-system, BlinkMacSystemFont, "Segoe UI",
        Helvetica, "Apple Color Emoji", Arial, sans-serif, "Segoe UI Emoji",
        "Segoe UI Symbol";
      font-weight: 900;
    }
    h3 {
      font-size: 24px;
      margin-top: 30px;
    }
    a {
      border-bottom: 1px solid rgba(0, 0, 0, 0.3);
    }
    p {
      font-size: 16px;
      line-height: 1.5;
      padding: 5px 0;
    }

    .left_bordered {
      border-left: 4px solid rgb(55, 53, 47);
      margin: 30px 0;
      padding-left: 20px;
      font-style: italic;
    }
  }
}

@media (max-width: "728px") {
  .foundation {
    .hero {
      height: 300px;
       margin-bottom: 70px;
      .thumb {
        width: 90%;
        img {
          width: 90px;
          position: absolute;
          bottom: -40px;
        }
      }
    }
    & > .wrapper {
      width: 90%;
      h1 {
        font-size: 40px;
      }
      h3 {
        font-size: 24px;
        margin-top: 20px;
      }

      p {
        padding: 5px 0;
      }
      .left_bordered {
        margin: 25px 0;
        padding-left: 15px;
      }
    }
  }
}
