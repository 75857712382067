* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  text-decoration: none;
  list-style-type: none;
  scroll-behavior: smooth;
  font-family: ProductSans;
  font-weight: 400;
  font-size: 15px;
  &::-webkit-scrollbar {
    width: 0px;
    height: 0px;
  }
}

:root {
  --primary: #8a91a8;
  --white: #ffffff;
  --black: #121212;
}

@font-face {
  font-family: ProductSans;
  src: url("../assets/fonts/ProductSans/Product\ Sans\ Regular.ttf");
  font-weight: 400;
}
@font-face {
  font-family: ProductSans;
  src: url("../assets/fonts/ProductSans/Product\ Sans\ Bold.ttf");
  font-weight: 600;
}
@font-face {
  font-family: ProductSansLight;
  src: url("../assets/fonts/ProductSans/ProductSans-Light.ttf");
  font-weight: 300;
}

.wrapper {
  // width: 94%;
  margin: 0 auto;
  max-width: 1440px;
  font-family: ProductSans;
  font-weight: 400;
}
.wrapp {
  width: 94%;
  margin: 0 auto;
}
img {
  max-width: 100%;
}
button {
  cursor: pointer;
}

h1 {
  // font-weight: 600;
  font-size: 90px;
  line-height: 109px;
  font-family: ProductSans;
  @media (max-width: 1150px) {
    font-size: 70px;
    line-height: 90px;
  }
  @media (max-width: 670px) {
    font-size: 40px;
    line-height: 50px;
  }
}

h2 {
  font-family: ProductSans;
  font-weight: 400;
  font-size: 45px;
  line-height: 55px;
}
h3 {
  font-size: 20px;
  line-height: 30px;
  font-family: ProductSans;
  font-weight: 400;
}
h4 {
  font-size: 18px;
  line-height: 27px;
  font-weight: 600;
  font-family: ProductSans;
  font-weight: 400;
}
h5 {
  font-size: 15px;
  line-height: 21px;
  font-weight: 600;
}
p {
  font-weight: 400;
  font-size: 16px;
  // line-height: 30px;
  color: var(--black);
  font-family: ProductSans;
  font-weight: 400;
}

.gifImg {
  width: 30px;
  height: 30px;
  transform: rotate(90deg);
  opacity: 0.5;
}

.underlineEffect {
  position: relative;
  display: inline-block;
  color: #202124;

  &::after {
    content: "";
    position: absolute;
    bottom: 0;
    width: 0%;
    height: 3px;
    background-color: black;
    left: 0;
    transition: all 400ms ease-in-out;
  }

  &:hover {
    &::after {
      content: "";
      width: 100%;
    }
  }
}
header {
  padding: 2px 0;
  padding: 25px 0;
  position: sticky;
  z-index: 2;
  top: 0px;
  left: 0;
  background-color: #ffffff;
  & > .wrapp {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 90%;
  }
  .nav {
    text-align: right;
    display: flex;
    a {
      margin-left: 15px;
      color: var(--black);
      display: block;
      margin-bottom: 16px;
      font-size: 15px;
    }
    & > .active {
      font-weight: 600;
    }
  }
  .menu {
    display: none;
  }

  .logo_wrapper {
    background-color: #ffffff;
  }
}
.mobile_menu {
  display: none;
}
footer {
  padding: 40px 0;
  padding-top: 90px;
  width: 100%;
  color: var(--primary);
  font-family: CourierPrime;
  font-weight: 400;
  div {
    a {
      display: inline-block;
      margin-bottom: 10px;
      color: var(--black);
      text-decoration: underline;
      font-family: ProductSans;
      font-weight: 400;
    }
  }
  & > div:nth-child(2) {
    margin-bottom: 59px;
    margin-top: 43px;
    .tel {
      text-decoration: none;
    }
  }
}
.downloadCv {
  cursor: pointer;
}
.pageTitle {
  width: 85%;
  margin: 0 auto;
  margin-bottom: 50px;
  margin-top: 50px;

  h1 {
    font-weight: 400;
  }
  & > div {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
    .titleCase {
      display: flex;
      align-items: center;
      width: 100%;
      font-size: 150.353px;
      font-weight: 400;
      line-height: 150px;
      border-bottom: 2px solid #000000;
      margin-bottom: 40px;
      padding-bottom: 70px;

      img {
        margin-left: -50px;
        padding-right: 50px;
        cursor: pointer;
      }
      p {
        display: flex;
        align-items: center;
        align-self: flex-end;
        font-size: 15px;
        width: 30%;
      }
    }
    .thickBlack {
      position: relative;
      padding-left: 90px;
      margin-left: 20px;
      &::before {
        content: "";
        width: 70px;
        height: 18px;
        background-color: black;
        position: absolute;
        left: 0;
        top: 0;
      }
      span {
        a {
          color: #000000;
          // text-decoration: underline;
          // padding-bottom: 20px;
          border-bottom: 1px solid black;
        }
      }
      .title {
        display: flex;
        align-items: flex-end;
        width: 80%;
        h1 {
          margin-bottom: -30px;
          display: flex;
          align-items: center;
          font-weight: 400;
          img {
            margin-right: 80px;
          }
        }
        .titleCase {
          // background-color: red;
          border-bottom: 2px solid black;
          width: 100%;
        }
      }
      .titleWritting {
        width: 20%;
        text-align: right;
        color: #202124;
        p {
          a {
            color: #202124;
          }
        }
      }

      .contacts {
        p {
          margin-top: 10px;
          padding-right: 8px;
          text-align: right;
          a {
            color: var(--black);
          }
        }
      }
    }
    .titleInfo {
      width: 20%;
      text-align: right;
      color: #3c4043;
      opacity: 0.5;
      a,
      span {
        color: #3c4043;
        text-decoration: underline;
      }
      p {
        margin-top: 8px;
        text-align: right;
      }
    }
  }
  footer {
    padding: 40px 0;

    .row1 {
      margin-bottom: 30px;

      & > div:nth-child(1) {
        grid-column: span 6;
      }

      & > div:nth-child(2) {
        grid-column: span 6;
      }
    }
  }
}

@media (max-width: 1150px) {
  header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px 0;
    div {
      display: flex;
      justify-content: space-between;
      align-items: center;
      & > div:nth-child(2) {
        display: none;
      }
    }
    .menu {
      width: 22px;
      display: block;
    }
  }
  .mobile_menu {
    display: block;
    position: fixed;
    top: 70px;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #ffffff;
    z-index: 2;
    text-align: center;
    li {
      &:hover {
        background-color: #000000;
        color: #ffffff;
        a {
          color: #ffffff;
        }
      }
      margin: 20px 0;
      a {
        color: #000000;
        padding: 20px;
        display: block;
        width: 100%;
      }
    }
  }
  .pageTitle {
    width: 85%;
    margin: 0 auto;
    margin-bottom: 50px;
    margin-top: 50px;
    & > div {
      align-items: flex-start;
      margin-bottom: 10px;
      flex-direction: column;
      .titleCase {
        display: flex;
        align-items: flex-start;
        flex-direction: column;
        width: 100%;
        font-size: 45px;
        line-height: 70px;
        margin-bottom: 40px;
        padding-bottom: 40px;
        img {
          margin-left: 0px;
          padding-right: 0px;
          margin-bottom: 15px;
        }
        p {
          align-self: flex-start;
          margin-top: 20px;
          width: 100%;
        }
      }
      h1 {
        line-height: 50px !important;
        margin-bottom: 30px !important;
      }
      .thickBlack {
        padding-left: 50px;
        margin-left: 0px;
        &::before {
          content: "";
          width: 40px;
          height: 20px;
        }
      }
      .titleInfo {
        width: 100%;

        p {
          margin-top: 4px;
          text-align: left;
        }
      }
    }
    .title {
      display: flex;
      align-items: flex-end;
      width: 80%;
      h1 {
        margin-bottom: -30px;
        display: flex;
        align-items: center;
        font-weight: 400;

        img {
          margin-right: 80px;
        }
      }
      .titleCase {
        border-bottom: 2px solid black;
        width: 100%;
      }
    }
    .titleWritting {
      width: 100%;
      text-align: left;
      p {
        a {
          color: #202124;
        }
      }
    }
  }
}

@media (max-width: 728px) {
  h2 {
    font-size: 28px;
    line-height: 40px;
  }

  header {
    .wrapp {
      align-items: center;
    }
    .logo_wrapper {
      background-color: #ffffff;
      img {
        width: 40px;
      }
    }
  }

  .wrapper {
    margin: 0 auto;
    max-width: 1440px;
  }
  .wrapp {
    width: 90%;
    margin: 0 auto !important;
  }
  .caseTitleWrap {
    width: 90%;
    margin: 0 auto;
  }
  .pageTitle {
    width: 100%;
    margin-bottom: 30px;

    .title {
      font-size: 50px;
      h1 {
        font-weight: 400;

        img {
          margin-right: 80px;

          margin-bottom: 20px;
        }
      }
    }
    .contacts {
      p {
        text-align: left !important;
      }
    }
  }
}
